:root {
  --white-text: #fff;
  --grey-text: #818795;
  --primary-red: #f23645;
  --green-text: #22ab94;
  --yellow-text: #e9c713;
  --primary-purple: #6a0bff;
  --purple: #8a52b0;
  --orange: #dc7e3a;
}

.white-text {
  color: var(--white-text) !important;
}

.grey-text {
  color: var(--grey-text);
}

.red-text {
  color: var(--primary-red);
}

.green-text {
  color: var(--green-text);
}

.yellow-text {
  color: var(--yellow-text);
}

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.sc-aXZVg {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 5px;
  /* border: 2px dashed var(--primary-purple) !important; */
  border: 2px dashed var(--purple) !important;
}

.sc-aXZVg svg {
  color: var(--purple) !important;
  /* color: var(--primary-purple) !important; */
}

.thumbnail-preview-container,
.image-preview-container {
  position: relative;
  width: 250px;
  height: 250px;
  border: 2px dotted rgb(205, 204, 204);
  margin-top: 30px;
}

.image-preview-container {
  margin: 30px 10px 10px 0px !important;
}

.thumbnail-preview-container svg,
.image-preview-container svg {
  position: absolute;
  top: -9px;
  right: -6px;
  background-color: red;
  border-radius: 20px;
  color: #fff;
  width: 70px;
}

.image-container {
  display: flex;
}

.thumbnail-image-prview {
  padding: 4px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-prview {
  padding: 4px;
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}

.sidebar .sidebar-nav::-webkit-scrollbar-track {
  border: none;
  padding: 2px 0;
  background-color: transparent;
}

.sidebar .sidebar-nav::-webkit-scrollbar {
  width: 5px;
}

.sidebar .sidebar-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--grey-text);
  border: none;
}

::-webkit-scrollbar-track {
  border: none;
  padding: 2px 0;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--grey-text);
  border: none;
}

/* .ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 11111;
} */
.ReactModal__Overlay {
  /* background-color: rgba(0, 0, 0, 0.8) !important; */
  z-index: 111;
}

.ReactModal__Content {
  padding: 0 !important;
  background-color: #181818 !important;
  border: none !important;
  width: 90%;
  border-radius: 15px !important;
}

.ReactModal__Content .btn-default {
  color: var(--white-text) !important;
}

/* Header and SideNav CSS */
.wrapper {
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  right: 0;
}

.wrapper .body {
  /* height: 90vh; */
  overflow-y: scroll;
  padding-bottom: 30px;
  padding-top: 20px;
  background: linear-gradient(45deg, #f4f6ff, #ebeef8);
  background-size: cover;
}

.header.header-sticky {
  padding: 0.428rem !important;
  /* box-shadow: 0px 0px 5px; */
}

.header.header-sticky .pi {
  font-size: 1.55rem;
}

.bg-light {
  background-color: #181818 !important;
}

@media screen and (max-width: 779px) {
  .header.header-sticky .pi {
    font-size: 1.15rem;
  }
}

.sidebar {
  box-shadow: 0px 2px 5px #bdbcbc !important;
}

.wrapper .header,
.sidebar .sidebar-brand {
  background: #ffffff;
  color: var(--grey-text);
  border: none;
  /* border-bottom: 1px solid grey; */
  /* box-shadow: 0px 0px 5px; */
}
.sidebar .sidebar-brand {
  text-align: center;
  display: flex !important;
  justify-content: center !important;
  padding-left: 0px !important;
}
.sidebar .sidebar-brand img {
  line-height: 0.8;
  margin-top: 5px;
  width: 150px;
  padding: 10px;
}
.wrapper .header a,
.wrapper .header button {
  color: var(--grey-text) !important;
}

.sidebar .sidebar-nav {
  background: #ffffff;
  background-size: cover;
  background-position: center;
}

.sidebar .sidebar-nav .simplebar-wrapper {
  margin-top: 20px !important;
  background-color: #ffffff;
  border-radius: 0px 15px 0px 0px;
  /* min-height: 98vh; */
}

.sidebar .sidebar-nav .simplebar-wrapper .simplebar-mask {
  padding-top: 0px;
  margin-right: 20px;
}

.sidebar-nav .nav-group.show {
  background-color: transparent !important;
}

.sidebar-nav .nav-link.active {
  color: var(--white-text) !important;
  /* background: var(--primary-purple) !important; */
  background: #8a52b0 !important;
  border-radius: 0px 5px 5px 0px;
}

.sidebar-nav .nav-link {
  font-weight: 500 !important;
  font-size: 15px !important;
  color: var(--grey-text) !important;
  padding: 12px 12px 12px 40px !important;
}

/* .sidebar-nav .nav-group .nav-group-items {
    padding-left: 15px !important;
  } */

.sidebar-nav
  .nav-group
  .nav-group-items
  .nav-group
  .nav-group-items
  .nav-item
  .nav-link {
  padding-left: 40px !important;
}

.simplebar-placeholder {
  display: none !important;
}

.sidebar-nav .nav-group-toggle::after {
  display: block;
  flex: 0 1 12px;
  height: 25px !important;
  margin-left: auto;
  content: "";
  background-image: url(./assets/images/down.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.15s;
}

@media (max-width: 767.98px) {
  .sidebar-backdrop {
    /* background: #0B0B0B url(./assets/images/background.png); 
      background-size: cover; */
    background-color: #0b0b0b !important;
    opacity: 0.7 !important;
  }
}

.card {
  background-color: #fff !important;
  border: none !important;
  /* padding: 5px 20px; */
  border-radius: 15px !important;
}

.card .card-header {
  border-radius: 15px 15px 0px 0px !important;
  border-bottom: none !important;
  background-color: #fff;
}

/* Form CSS */

.form-group {
  margin-bottom: 15px;
}

input.form-control,
select.form-control,
textarea.form-control{
/* input.form-control:focus,
select.form-control:focus,
textarea.form-control:focus { */
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  width: 100%;
  /* border: 1px solid var(--orange); */
  border: 1px solid var(--grey-text);
  padding: 10px;
  color: #000;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  height: 50px;
}

input.form-control:focus,
select.form-control:focus,
textarea.form-control:focus {
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  /* border: 1px solid var(--orange); */
  border: 1px solid var(--grey-text);
  padding: 10px;
  color: #000;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  height: 50px;
}

.form-control:disabled {
  background-color: #f0f0f0 !important;
  color: #000 !important;
  opacity: 1;
}

form label {
  color: #000;
  font-size: 15px;
  /* font-weight:100; */
  margin-bottom: 3px;
}

.form-header p,
.table-header p {
  font-size: 15px;
}

.primary-btn {
  /* background-color: var(--primary-purple) !important; */
  background-color: #8a52b0 !important;
  color: #fff !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.secondary-btn {
  /* background-color: #E67F3B !important; */
  background-color: #dc7e3a !important;
  color: #fff !important;
}

.primary-btn img {
  width: 18px;
}

.img-container > label:nth-child(2) {
  width: 100% !important;
  min-width: 100% !important;
}

/* Table CSS */

.search-container input[type="search"]::placeholder {
  color: var(--grey-text);
}

.table-responsive {
  background-color: #181818 !important;
}

.table-responsive table {
  border: none !important;
}

.table-responsive table thead th {
  background-color: #fff;
  border: none !important;
  color: #000;
  padding: 15px 20px 15px 20px;
  font-weight: 500;
}

.table-responsive table tbody tr {
  /* border-bottom: 1.5px solid #262626; */
}

.table-responsive table tbody p {
  margin-bottom: 0px !important;
}

.table-responsive table tbody td {
  /* background-color: transparent !important; */
  padding: 5px 20px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 771px) {
  .table-responsive table thead th,
  .table-responsive table tbody td {
    font-size: 13px;
  }
}

/* Pagination CSS */
.p-paginator {
  /* background: #181818 !important; */
  border-radius: 0px 0px 15px 15px !important;
}

.p-paginator .p-dropdown {
  background: #8a52b0;
  border: none;
  box-shadow: none !important;
}
.p-paginator .p-dropdown .p-dropdown-label {
  color: #fff !important;
}
.p-dropdown .p-dropdown-trigger {
  color: #fff !important;
}

.p-dropdown-panel .p-dropdown-items {
  background: #fff;
  border-radius: 7px;
  margin: 0px;
  padding-left: 0px;
}
.p-dropdown-items-wrapper {
  overflow: hidden !important;
  max-height: 250px !important ;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #000 !important;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
  text-align: center;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--white-text) !important;
  width: "100px";
  background: var(--purple) !important;
  /* background: var(--primary-purple) !important; */
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  padding: 10px;
  border-radius: 5px;
  background: #8a52b0 !important;
  border-color: transparent !important;
  color: var(--white-text) !important;
  /* color: var(--black-text) !important; */
}

/* ..........serch........ */
.search-filed {
  margin-bottom: 30px;
}
.full-size-button {
  width: 100%;
  height: 50px;
  font-size: 16px !important;
}

.time-end-dest {
  margin-bottom: 30px;
}
.time-end-dest p {
  font-size: 18px;
  margin: 0px;
  text-align: center;
}
.time-end-dest h4 {
  font-size: 20px;
  margin: 0px;
}
/* Chips CSS */
.p-chips {
  display: block !important;
  width: 100% !important;
  background-color: #262626 !important;
  border: none !important;
}

.p-chips .p-chips-multiple-container {
  padding: 0px !important;
  background-color: #262626 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: var(--white-text) !important;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "Montserrat" !important;
  font-size: 1rem;
  color: var(--white-text) !important;
  padding: 0;
  margin: 0;
}

/* Multi Select CSS */

.p-multiselect-panel {
  background: #262626 !important;
  color: var(--white-text) !important;
  border: 0 none;
  border-radius: 6px;
  box-shadow: none !important;
}

.p-multiselect {
  background: #262626 !important;
  border: none !important;
  transition: none !important;
  border-radius: 6px;
  box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #262626 !important;
  color: var(--grey-text) !important;
  background: #262626 !important;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-multiselect-panel .p-multiselect-header .p-inputtext {
  font-family: "Montserrat" !important;
  font-size: 1rem;
  color: var(--white-text) !important;
  background: #181818 !important;
  padding: 0.55rem 0.75rem;
  border: 1px solid #181818 !important;
  transition: none !important;
  appearance: none;
  border-radius: 6px;
  box-shadow: none !important;
}

/* 
  .p-checkbox .p-checkbox-box {
    border: 2px solid #181818 !important;
    background: #181818 !important;
    width: 22px;
    height: 22px;
    color: #495057;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  } */

.p-multiselect-label {
  color: var(--white-text);
  padding: 0.55rem 0.75rem !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #4338ca;
  background: #262626 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: var(--white-text) !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover {
  background-color: #181818 !important;
}

/* Accordion CSS */
.p-accordion-header,
.p-accordion .p-accordion-header .p-accordion-header-link,
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background-color: #262626 !important;
  border: none;
  border-radius: 5px !important;
  border-bottom: 1px solid #909090 !important;
  box-shadow: none !important;
}

.p-accordion .p-accordion-content {
  background-color: #262626 !important;
  color: var(--white-text) !important;
  border: none !important;
}

/* Tab CSS */
.p-tabview .p-tabview-nav {
  background: #181818 !important;
  border: 1px solid #181818 !important;
  border-radius: 15px 15px 0px 0px;
}

.p-tabview .p-tabview-nav li {
  border: none;
  background: transparent !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none !important;
  /* border-color: transparent transparent #181818 transparent; */
  background: #181818 !important;
  color: #909090 !important;
  padding: 1.25rem;
  font-weight: 500;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #6a0bff !important;
  /* border-bottom: 2px solid #ff0b50 !important; */
  /* border-color: #6A0BFF !important; */
  color: var(--white-text) !important;
}

.p-tabview .p-tabview-panels {
  background: #181818 !important;
  /* padding: 0px !important; */
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  background-color: #6a0bff !important;
}

/* Loader CSS */
.table-responsive .loader {
  position: relative;
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(261%, 0%); */
  height: 100%;
  margin: 50px auto;
}

.table-responsive .loader > div > svg {
  background-color: transparent !important;
}

.table-responsive .mapped-table .loader {
  position: relative;
  /* left: 50%;
    top: 50%;
    transform: translate(125%, 0%) !important; */
  height: 100%;
  margin: 50px auto;
}

.loader {
  /* margin: 100px auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loader > div {
  /* align-content: center; */
  justify-content: center;
}


.loaderForm {
  /* margin: 100px auto; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderForm > div {
  /* align-content: center; */
  justify-content: center;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  -ms-touch-action: none;
  touch-action: none;
}

/* Common Mobile Resposive CSS */

@media screen and (max-width: 771px) {
  form div.red-text {
    font-size: 13px;
  }

  button {
    font-size: 12px !important;
  }
}

.dropzone {
  border: 2px dashed #6a0bff;
  padding: 11px;
  /* text-align: center; */
  cursor: pointer;
  border-radius: 8px;
}

.dropzone p {
  margin: 0;
}

.thumbnail-preview-container {
  margin-top: 10px;
  width: 300px;
  /* width: inherit; */
  height: inherit;
}

.thumbnail-image-prview {
  max-width: 100%;
  max-height: 150px;
  margin-top: 10px;
}

.btn-cancel {
  background-color: lightgrey;
}
.btn {
  border-radius: 4px !important;
}

.cancle-btn {
  background-color: #d3d3d3 !important;
  font-size: 13px !important;
  padding: 10px 30px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}
.btun-pd {
  font-size: 13px !important;
  padding: 10px 30px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.confirmation-modal {
  border: 1px solid #232323;
  border-radius: 7px;
}

.bg-card-back {
  background-color: #8a52b0;
  /* background-image: linear-gradient(90deg, #dc7e3a, #8a52b0); */
  color: #fff;
  text-align: center;
  margin: 10px 0px;
  padding: 10px;
}
.loaderHeight{
  height: 10vh !important ;
}